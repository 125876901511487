import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router/index";
import Vuex from "vuex";
import { Select, Option } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import ElementUI from 'element-ui';
import "../src/assets/css/reset.css";
import "../src/assets/css/style.css";
import Header from "../src/components/header.vue";
import Footer from "/src/components/footer.vue";
import Pagination from "/src/components/pagination.vue";
import Popup from "/src/components/popup.vue";

// import MetaInfo from "vue-meta-info";

Vue.use(Vuex);
Vue.use(Select);
Vue.use(Option);
// Vue.use(MetaInfo);

Vue.component("Header", Header);
Vue.component("Footer", Footer);
Vue.component("Pagination", Pagination);
Vue.component("Popup", Popup);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  // mounted() {
  //   document.dispatchEvent(new Event("render-event"));
  // },
}).$mount("#app");
