<template>
  <div class="header_fix">
    <div class="header flex-box max_1440">
      <img src="../assets/static/icon/icon_logo1.png" class="logo" alt="" />
      <div class="flex-box flex-grow-1 nav_box" :class="showMenu ? 'show' : ''">
        <router-link to="/" class="nav" :class="headerIndex == 0 ? 'active' : ''">HOME</router-link>
        <router-link to="/about" class="nav" :class="headerIndex == 1 ? 'active' : ''">ABOUT RWF</router-link>
        <router-link to="/productcenter" class="nav" :class="headerIndex == 2 ? 'active' : ''">PRODUCTS</router-link>
        <router-link to="/Customize" class="nav" :class="headerIndex == 8 ? 'active' : ''">CUSTOMIZE</router-link>
        <router-link to="/colorcard" class="nav" :class="headerIndex == 3 ? 'active' : ''">COLOR CARD</router-link>
        <router-link to="/case" class="nav" :class="headerIndex == 4 ? 'active' : ''">CASE SHOW</router-link>
        <router-link to="/posterlist" class="nav" :class="headerIndex == 10 ? 'active' : ''">POSTER</router-link>
        <div class="nav_parent_wrap">
          <router-link to="/pre-cut" class="nav nav_parent" :class="headerIndex == 11 ? 'active' : ''">PRE-CUT</router-link>
          <div class="nav_son_wrap">
            <!-- <div class="nav_son" @click="clickSon">KIT Search</div> -->
            <router-link to="/kit-search" class="nav_son" :class="headerIndex == 11 ? 'active' : ''">KIT Search</router-link>
          </div>
        </div>
        <router-link to="/distributors" class="nav" :class="headerIndex == 6 ? 'active' : ''">DISTRIBUTORS</router-link>
        <router-link to="/training" class="nav" :class="headerIndex == 9 ? 'active' : ''">TRAINING</router-link>
        <router-link to="/blog" class="nav" :class="headerIndex == 7 ? 'active' : ''">BLOG</router-link>
        <router-link to="/contact" class="nav" :class="headerIndex == 5 ? 'active' : ''">CONTACT </router-link>
      </div>
      <div class="menu" :class="showMenu ? 'show' : ''" @click="toggleMenu()">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {},
  mounted() {},
  props: {
    headerIndex: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    // clickSon() {
    //   this.$router.push("pre-cut");
    //   this.$emit("showSearch");
    // },
  },
};
</script>
<style scoped>
.nav_parent_wrap {
  position: relative;
}
.nav_parent_wrap .nav_parent {
  display: block;
}
.nav_son_wrap {
  position: absolute;
  top: 60px;
  left: -20px;
  right: -20px;
  padding: 10px 0;
  display: none;
  color: #333;
  z-index: 1;
  text-align: center;
}
.nav_son_wrap .nav_son:hover {
  color: var(--red);
}
.nav_parent_wrap:hover .nav_son_wrap {
  display: block;
  background: rgba(255, 255, 255, 1);
}
.nav_parent_wrap .nav_son {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .nav_parent_wrap {
    display: flex;
    justify-content: center;
  }
  .nav_son_wrap {
    position: static;
    display: block;
    padding: 10px 5px;
    margin-left: 15px;
  }
  .nav_parent_wrap:hover .nav_son_wrap {
    background: unset;
  }
  .nav_parent_wrap .nav_son{
    color: #fff;

  }
}
</style>
