import Vue from "vue";
import VueRouter from "vue-router";

import Index from "../views/Index.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "/about",
    name: "about",
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/productcenter",
    name: "productcenter",
    component: () => import(/* webpackChunkName: "productcenter" */ "../views/ProductCenter.vue"),
  },
  {
    path: "/productdetail",
    name: "productdetail",
    component: () => import(/* webpackChunkName: "productdetail" */ "../views/ProductDetail.vue"),
  },
  {
    path: "/colorcard",
    name: "colorcard",
    component: () => import(/* webpackChunkName: "colorcard" */ "../views/ColorCard.vue"),
  },
  {
    path: "/colorcarddetail",
    name: "colorcarddetail",
    component: () => import(/* webpackChunkName: "colorcarddetail" */ "../views/ColorCardDetail.vue"),
  },
  {
    path: "/case",
    name: "case",
    component: () => import(/* webpackChunkName: "case" */ "../views/Case.vue"),
  },
  {
    path: "/casedetail",
    name: "casedetail",
    component: () => import(/* webpackChunkName: "casedetail" */ "../views/CaseDetail.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/distributors",
    name: "distributors",
    component: () => import(/* webpackChunkName: "distributors" */ "../views/Distributors.vue"),
  },
  {
    path: "/pre-cut",
    name: "pre-cut",
    component: () => import(/* webpackChunkName: "distributors" */ "../views/Pre-Cut.vue"),
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import(/* webpackChunkName: "blog" */ "../views/Blog.vue"),
  },
  {
    path: "/blogdetail",
    name: "blogdetail",
    component: () => import(/* webpackChunkName: "blogdetail" */ "../views/BlogDetail.vue"),
  },
  {
    path: "/customize",
    name: "customize",
    component: () => import(/* webpackChunkName: "customize" */ "../views/Customize.vue"),
  },
  {
    path: "/training",
    name: "training",
    component: () => import(/* webpackChunkName: "training" */ "../views/Training.vue"),
  },
  {
    path: "/posterlist",
    name: "posterlist",
    component: () => import(/* webpackChunkName: "posterlist" */ "../views/PosterList.vue"),
  },
  {
    path: "/posterdetail",
    name: "posterdetail",
    component: () => import(/* webpackChunkName: "posterdetail" */ "../views/PosterDetail.vue"),
  },
  {
    path: "/kit-search",
    name: "kit-search",
    component: () => import(/* webpackChunkName: "distributors" */ "../views/Kit-Search.vue"),
  },
];
const router = new VueRouter({
  // base: process.env.BASE_URL,
  // mode:'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
