<template>
  <div>
    <Header :headerIndex="0"></Header>
    <div class="container">
      <swiper v-if="banner.length>0" class="index_swiper1" :options="swiperOption" ref="indexSwiper1">
        <swiper-slide v-for="(item, index) in banner" :key="index">
          <div>
            <img :src="item.image" class="img_banner" alt="" />
          </div>
        </swiper-slide>
        <div class="swiper-pagination index_pagination" slot="pagination"></div>
      </swiper>
      <!-- <swiper-slide>
          <div>
            <img src="../assets/static/images/img_banner1.png" class="img_banner" alt="" />
          </div>
        </swiper-slide> -->
      <div class="index_menu">
        <div class="max_1440 flex-box flex-between flex-col-start">
          <router-link :to="{ path: '/About', hash: '#'+item.id }" class="menu_item" v-for="(item, index) in menulist">
            <!-- <div class="icon_menu" :class="'icon' + item.id"> -->
              <img class="icon_menu" :src="item.icon_image" />
            <!-- </div> -->
            <div class="title">{{ item.name }}</div>
            <div class="desc m-ellipsis-l3" v-html="item.detail">
              <!-- {{ item.detail }} -->
            </div>
          </router-link>
          <!-- <router-link to="/About" class="menu_item">
            <div class="icon_menu icon2"></div>
            <div class="title">POSITIONING</div>
            <div class="desc m-ellipsis-l3">
              The RWF brand is positioned to provide professional services and professionalism; Excellent effect comes from the
              clever …
            </div>
          </router-link>
          <router-link to="/About" class="menu_item">
            <div class="icon_menu icon3"></div>
            <div class="title">PLAN</div>
            <div class="desc m-ellipsis-l3">
              RWF is committed to developing professional and reliable products and services globally, focusing on supply…
            </div>
          </router-link>
          <router-link to="/About" class="menu_item">
            <div class="icon_menu icon4"></div>
            <div class="title">AFTER SALES</div>
            <div class="desc m-ellipsis-l3">
              Quality Commitment: Free after-sales warranty for PET color changing film products for 2 years TPU colore…
            </div>
          </router-link> -->
        </div>
      </div>

      <div class="index_products">
        <div class="index_products_inner max_1440">
          <div class="heading flex-box flex-between">
            <div class="flex-grow-1">RECOMMENDED PRODUCTS</div>
            <router-link to="/ProductCenter" class="more flex-box flex-center">
              <div>MORE</div>
              <img class="icon-arrow" src="../assets/static/icon/icon_arrow1.png" />
            </router-link>
          </div>

          <swiper v-if="product.length>0" class="products_swiper" :options="productsSwiperOption" ref="productsSwiper">
            <swiper-slide v-for="(item, index) in product" :key="index">
              <router-link :to="{ path: '/ProductDetail', query: { productid: item.id } }" class="products_item">
                <img :src="item.thumb_image" class="img" alt="" />
                <div class="desc m-ellipsis">{{ item.introduce }}</div>
              </router-link>
            </swiper-slide>
            <!-- <swiper-slide>
              <router-link to="" class="products_item">
                <img src="../assets/static/images/img_1.png" class="img" alt="" />
                <div class="desc m-ellipsis">TPU paint color changing car clothing</div>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link to="" class="products_item">
                <img src="../assets/static/images/img_1.png" class="img" alt="" />
                <div class="desc m-ellipsis">TPU paint color changing car clothing</div>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link to="" class="products_item">
                <img src="../assets/static/images/img_1.png" class="img" alt="" />
                <div class="desc m-ellipsis">TPU paint color changing car clothing</div>
              </router-link>
            </swiper-slide> -->
            <div class="swiper-pagination products_pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>

      <div class="index_case">
        <div class="max_1440">
          <div class="heading flex-box flex-between">
            <div class="flex-grow-1">CASE SHOW</div>
            <router-link to="/Case" class="more flex-box flex-center">
              <div class="m-arrow-right">
                <div class="m-cell-rt">MORE</div>
              </div>
            </router-link>
          </div>

          <div class="g_case_list flex-box flex-wrap flex-col-start">
            <router-link
              :to="{ path: '/CaseDetail', query: { caseid: item.id } }"
              class="case_item"
              v-for="(item, index) in caselist"
              :key="index"
            >
              <!-- <img class="img" :src="item.fpicurl" alt="" /> -->
              <div class="img" :style="{'background-image':'url(' + item.fpicurl +')'}"></div>
              <div class="name m-ellipsis">{{ item.title_en }}</div>
              <div class="date">{{ item._posttime1 }}</div>
              <div class="desc m-ellipsis-l2">
                {{ item.content_en != "" ? item.content_en : "No details available at the moment" }}
              </div>
              <div class="flex-box flex-between">
                <img class="logo" :src="item.shop_logo" alt="" />
                <div class="btn"></div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import api from "../api";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import axios from "axios";

export default {
    // metaInfo() {
    //   return {
    //     title: 'RWF',
    //     meta: [{
    //       name: 'RWF',
    //       content: 'RWF汽车贴膜'
    //     }]
    //   }
    // },
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    const generateProductsOptions = (_) => {
      var option = {};
      if (document.documentElement.clientWidth > 767) {
        option = {
          loop: true,
          slidesPerView: 3,
          centeredSlides: true,
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".products_pagination",
          },
        };
      } else {
        option = {
          loop: true,
          spaceBetween: 0,
          slidesPerView: 1,
          centeredSlides: true,
          autoplay: {
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          pagination: {
            el: ".products_pagination",
          },
        };
      }
      return option;
    };
    return {
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        observer:true, 
        observeParents: true,  
        pagination: {
          el: ".index_pagination",
        },
      },
      //轮播图
      banner: [],
      product: [],
      //品牌承诺
      menulist: [],
      //案例推荐
      caselist: [],
      productsSwiperOption: generateProductsOptions(),
    };
  },
  //方法
  methods: {
    //菜单
    getmenu() {
      api.doGet("/api/banner/getmenu", {}, (res) => {
        this.menulist = res.data;
        // console.log(this.menulist)
      });
    },
    //轮播
    getbanner() {
      api.doPost("/api/banner/getbanner", {}, (res) => {
        // console.log(res);
        this.banner = res.data;
          this.$nextTick(() => {
            console.log(1); 
            this.swiper1.update();
          });
      });
    },
    //获取推荐的产品
    precommend() {
      api.doGet("/api/banner/precommend", {}, (res) => {
        console.log(res.data);
        this.product = res.data;
      });
      // axios.get('http://www.rwf.com/api/banner/precommend').then(res=>{
      //   console.log(res.data)
      //   this.product = res.data
      // })
    },
    crecommned() {
      var json = {
        page: 1,
        limit: 4,
        indexrecommend: 1,
      };
      axios.post("https://n.xilukeji.com/rwf/index.php/Shop/Shop/community_2023", json).then((res) => {
        console.log(res.data);
        this.caselist = res.data.info;
      });
    },
  },
  created() {
    // console.log('1111111111')
    this.getmenu();
    this.getbanner();
    this.precommend();
    this.crecommned();
  },
  computed: {
    swiper1() {
      return this.$refs.indexSwiper1.swiper;
    },
    swiper2() {
      return this.$refs.productsSwiper.swiper;
    },
  },
};
</script>
