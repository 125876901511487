<template>
  <div class="pagination">
    <!-- <a href="javascript:;" @click="pageTurn(0)" class="first_last font_hg">&lt;&lt;</a> -->
    <a href="javascript:;"  @click="pageTurn(paging.index - 1)" class="first_last font_hg" :class="{'disable': paging.index == 0}">previous page</a>
    <template v-for="i in sum">
      <a
        href="javascript:;"
        @click="pageTurn(i - 1)"
        :key="i"
        v-if="showText(i)"
        v-text="showText(i)"
        :class="i === now ? 'active' : 'page'"
        class="font_hg"
      >
      </a>
      <!-- :class="i === paging.index + 1 ? 'active' : 'page'" -->

    </template>
    <a href="javascript:;" @click="pageTurn(paging.index + 1)" class="first_last font_hg" :class="{'disable': paging.index == sum - 1}">next page</a>
    <!-- <a href="javascript:;" @click="pageTurn(sum - 1)" class="first_last font_hg">&gt;&gt;</a> -->
  </div>
</template>
<script>
export default {
  props: ["sumCount", "perCount" ,'now'],
  data() {
    return {
      paging: {
        index: 0, // index
        count: 5, // count
      },
    };
  },
  computed: {
    sum() {
      // sum
      return Math.ceil(this.sumCount / this.perCount);
    },
  },
  methods: {
    // （）
    showText(i) {
      const vm = this;
      const index = vm.paging.index;
      const sum = vm.sum;
      if (document.documentElement.clientWidth < 767){
        return false;
      }
      if (i < 3 || i > sum - 2) {
        return i;
      } else if (i <= index + 2 && i >= index) {
        return i;
      } else if (i === index + 3 || i === index - 1) {
        return "...";
      }
    },
    //
    pageTurn(i) {
      console.log('pageTurn11',i);

      const vm = this;
      if (i < 0 || i >= vm.sum) {
        // || i === vm.paging.index 
        return ('1111'); 
      }
      vm.paging.index = i;
      console.log('vm.paging.index',i);
      // ，init
      vm.$emit("pageTurn", i, vm.perCount);
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pagination .first_last {
  min-width: 198px;
}

.pagination .first_last.disable{
  background: #999!important;
}

.pagination .page {
  min-width: 50px;
}

.pagination a {
  margin: 0 15px;
  padding: 0 10px;
  display: block;
  height: 50px;
  background: #333333;
  border-radius: 3px;
  text-align: center;
  line-height: 48px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.pagination a.active {
  background: #cf1f1a;
  min-width: 50px;
}
.pagination a:hover {
  background: #cf1f1a;
}

@media screen and (max-width: 1440px) {
  .pagination .first_last {
    min-width: 148px;
  }

  .pagination .page {
    min-width: 38px;
  }

  .pagination a {
    margin: 0 11px;
    padding: 0 6px;
    height: 38px;
    border-radius: 2px;
    line-height: 36px;
    font-size: 18px;
  }
  .pagination a.active {
    min-width: 38px;
  }
}

@media (max-width: 768px) {
  .pagination .first_last,
  .pagination .page,
  .pagination a.active {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
  .pagination a {
    margin: 0 5px;
  }
  .pagination .first_last {
    min-width: 130px;
  }

  .pagination .page {
    min-width: 30px;
  }
}
@media (min-width: 768px) {
  .pagination .first_last{
    display: none;
  }
}
</style>
