<template>
  <div id="app">
    <!-- <nav> -->
      <!-- <router-link to="/"></router-link> -->
      <!-- <router-link to="/about">About</router-link> -->
    <!-- </nav> -->
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
