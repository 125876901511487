<template>
  <div class="footer">
    <div class="footer_part1">
      <div class="max_1440 flex-box flex-between flex-col-start">
        <div class="part1">
          <!-- <div class="heading">SITE NAVIGATION</div> -->
          <div class="flex-box flex-col-start">
            <div class="mr30">
              <router-link to="/" class="footer_link">HOME</router-link>
              <router-link to="/About" class="footer_link">ABOUT RWF</router-link>
              <router-link to="/ProductCenter" class="footer_link">PRODUCTS</router-link>
              <router-link to="/Customize" class="footer_link">CUSTOMIZE</router-link>
              <router-link to="/ColorCard" class="footer_link">COLOR CARD</router-link>
              <router-link to="/Case" class="footer_link">CASE SHOW</router-link>
            </div>
            <div>
              <router-link to="/posterlist" class="footer_link">POSTER</router-link>
              <router-link to="/Distributors" class="footer_link">DISTRIBUTORS</router-link>
              <router-link to="/Training" class="footer_link">TRAINING</router-link>
              <router-link to="/Blog" class="footer_link">BLOG</router-link>
              <router-link to="/Contact" class="footer_link">CONTACT</router-link>
            </div>
          </div>
        </div>
        <div class="part2">
          <!-- <div class="heading mb20">CONTACT RWF</div>
          <div class="mb20">
            <span>Enterprise Name:</span>&nbsp;
            <span class="white">{{info.company}}</span>
          </div> -->
          <div class="mb20">
            <span>Address:</span>&nbsp;
            <span class="white">{{ info.addr }}</span>
          </div>
          <div class="mb20">
            <span>Phone:</span>&nbsp;
            <span class="white">{{ info.mobile }}</span>
          </div>
          <div>
            <span>Email:</span>&nbsp;
            <span class="white">{{ info.email }}</span>
          </div>
        </div>
        <div class="part3">
          <div class="heading mb20">Follow Us!</div>
          <!-- <div class="white mb20">CONNECT WITH US:</div> -->
          <!-- <div class="white mb20">Follow us on social media:</div> -->
          <div class="flex-box flex-wrap flex-col-start">
            <a :href="info.wxurl" class="outer_link link1"></a>
            <a :href="info.insurl" class="outer_link link3"></a>
            <a :href="info.youtubeurl" class="outer_link link4"></a>
            <a :href="info.tokurl" class="outer_link link2"></a>
            <a :href="info.whatsapp" class="outer_link link5"></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer_part2">
      <a href="https://beian.miit.gov.cn/" style="color: #9f9f9f">{{ beian }}</a>
    </div>
  </div>
</template>

<script>
import store from "../store";
import api from "../api";
export default {
  name: "Footer",
  data() {
    return {
      info: [],
      beian: "",
    };
  },
  computed: {},
  created() {
    this.getinfo();
    this.getbeian();
  },
  watch: {},
  destroyed() {},
  mounted() {},
  methods: {
    getinfo() {
      api.doGet("/api/banner/getinfo", {}, (res) => {
        // console.log(res);
        if (res.code == 200) {
          this.info = res.data;
        }
      });
    },
    getbeian() {
      api.doGet("/api/index/beian", {}, (res) => {
        // console.log(res);
        this.beian = res.data;
      });
    },
  },
};
</script>
