/*** Created By Nicky  ***/

/**
 * 
 * @param String key
 * @param Object obj
 */
const setCache = function(key,obj,callback){
  let _callback = callback||function(){}
  var data = typeof(obj)=='String'?obj:JSON.stringify(obj);
  window.sessionStorage.setItem(key,data)
  _callback(obj);
}
/**
 * 
 * @param String key
 */
const getCache = function(key,success,fail){
  let _success = success||function(){}
  let _fail = fail||function(){}
  let res = window.sessionStorage.getItem(key)
  if(res) {
    try{ var dat = JSON.parse(res); _success(dat); return dat  }catch(e){ _success(res); return res }
  }else{
    _fail(); return false
  }
}
/**
 * 
 * @param String key
 * @param Function success  
 * @param Function fail     
 */
const checkCache = function(key,success,fail){
  let _success = success||function(){}
  let _fail = fail||function(){}
  var dat = getCache(key)
  dat ? _success(dat) : _fail()
}

/**
 * 
 */
const clearCache = function(callback){
  let _callback = callback||function(){}
  window.sessionStorage.clear()
  _callback()
}

/**
 * 
 * @param String key
 * @param Object obj
 */
const setLocalCache = function(key,obj,callback){
  let _callback = callback||function(){}
  var data = typeof(obj)=='String'?obj:JSON.stringify(obj);
  window.localStorage.setItem(key,data)
  _callback(obj);
}
/**
 * 
 * @param String key
 */
const getLocalCache = function(key,success,fail){
  let _success = success||function(){}
  let _fail = fail||function(){}
  let res = window.localStorage.getItem(key)
  if(res) {
    try{ var dat = JSON.parse(res); _success(dat); return dat  }catch(e){ _success(res); return res }
  }else{
    _fail(); return false
  }
}
/**
 * 
 * @param String key
 * @param Function success  
 * @param Function fail     
 */
const checkLocalCache = function(key,success,fail){
  let _success = success||function(){}
  let _fail = fail||function(){}
  var dat = getCache(key)
  dat ? _success(dat) : _fail()
}

/**
 * 
 */
const clearLocalCache = function(callback){
  let _callback = callback||function(){}
  window.localStorage.clear()
  _callback()
}


export default {
	setCache,
	getCache,
  checkCache,
  clearCache,
  
	setLocalCache,
	getLocalCache,
  checkLocalCache,
  clearLocalCache


}
